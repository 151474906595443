import { Chip, type ChipProps } from "@/components/ui/data-display/Chip"
import { Typography } from "@/components/ui/data-display/Typography"
import type { NullableProps, SchemaPick } from "@/global"
import { useTranslation } from "@/i18n"
import { formatPersonName } from "@/utils/format"
import type { persons } from "dcp-types"
import { forwardRef, useEffect, useMemo, type HTMLAttributes } from "react"
import de from "../../_messages/de.json"
import PersonAvatar from "../PersonAvatar"
import { Tooltip } from "@/components/ui/data-display/Tooltip"
import { Stack } from "@/components/ui/layout/Stack"
import type { IconButtonProps } from "@mui/material"
import { IconButton } from "@/components/ui/inputs/IconButton"
import Icon from "@mdi/react"
import { mdiPencil } from "@mdi/js"
import { useTheme } from "@mui/material/styles"

export interface PersonChipProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "children" | "color"> {
  person?: NullableProps<
    SchemaPick<persons, "first_name" | "last_name" | "title" | "avatar_ref">
  >
  variant?: "chip" | "avatar"
  size?: "tiny" | "small" | "medium"
  tooltip?: string
  onDelete?: ChipProps["onDelete"]
  onEdit?: IconButtonProps["onClick"]
  onClick?: HTMLAttributes<HTMLDivElement | HTMLButtonElement>["onClick"]
}

/**
 * TODO: add support for different sizes
 */

export const PersonChip = forwardRef<HTMLDivElement, PersonChipProps>(
  (
    {
      person,
      variant = "chip",
      size = "small",
      tooltip,
      onDelete,
      onEdit,
      ...attributes
    },
    ref,
  ) => {
    const chipSize = size !== "tiny" ? size : "small"
    const { t } = useTranslation(de)
    const theme = useTheme()
    const chipTextColor = attributes.onClick ? "text.primary" : "text.disabled"

    const title = useMemo(
      () =>
        person?.first_name || person?.last_name
          ? formatPersonName(person)
          : t("missing.name"),
      [person, t],
    )

    useEffect(() => {
      if (variant === "avatar" && (onDelete || onEdit)) {
        console.error(
          "PersonChip: onDelete and onEdit are not supported for variant 'avatar'.",
        )
      }
    }, [onDelete, onEdit, variant])

    useEffect(() => {
      if (onDelete && onEdit) {
        console.error(
          "PersonChip: onDelete and onEdit are both defined. This is not supported, use either or.",
        )
      }
    }, [onDelete, onEdit])

    if (variant === "avatar") {
      const iconButtonSize = {
        tiny: "small",
        small: "medium",
        medium: "large",
      } as const

      const callback = attributes.onClick

      return (
        <div ref={ref} className="max-w-full">
          <Tooltip title={tooltip ?? title}>
            <div>
              <IconButton
                className="p-0"
                size={iconButtonSize[size]}
                onClick={(e) => attributes.onClick?.(e)}
                disabled={!attributes.onClick}
              >
                <PersonAvatar person={person} size={size} />
              </IconButton>
            </div>
          </Tooltip>
        </div>
      )
    }

    return (
      <div ref={ref} className="max-w-full">
        <Chip
          {...attributes}
          sx={{
            ["& .MuiChip-label"]: {
              paddingRight: onEdit ? 0 : undefined,
            },
            // @ts-expect-error
            ...(attributes?.sx ?? {}),
          }}
          className={`${size === "tiny" ? "h-5" : ""} ${attributes.className}`}
          avatar={
            <PersonAvatar className="ml-0.5" person={person} size={size} />
          }
          label={
            <Stack direction={"row"} alignItems={"center"}>
              <Tooltip title={tooltip ?? title}>
                <Typography
                  fontWeight={500}
                  variant="body2"
                  color={chipTextColor}
                >
                  {title}
                </Typography>
              </Tooltip>
              {onEdit && (
                <Tooltip title={t("edit.label")}>
                  <IconButton
                    disableRipple
                    sx={{
                      color: theme.palette.action.disabled,
                      ["&:hover"]: {
                        color: theme.palette.action.active,
                      },
                    }}
                    size="small"
                    onClick={(evt) => {
                      evt.stopPropagation()
                      return onEdit(evt)
                    }}
                  >
                    <Icon path={mdiPencil} size={0.75} />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
          }
          size={chipSize}
          onDelete={onDelete}
        />
      </div>
    )
  },
)

PersonChip.displayName = "PersonChip"

export default PersonChip
