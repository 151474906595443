import type { Nullable, NullableProps, SchemaPick } from "@/global"

import { Tooltip } from "@/components/ui/data-display/Tooltip"
import { useTranslation } from "@/i18n"
import { mdiCheckDecagram } from "@mdi/js"
import Icon from "@mdi/react"
import type { AvatarProps } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import type { organizations } from "dcp-types"

import Avatar, { type AvatarSize } from "@/app/_components/Avatar"
import { useOrganizationIcon } from "../../_hooks/useOrganizationIcon"
import de from "../../_messages/de.json"

export interface OrganizationAvatarProps extends AvatarProps {
  organization?: Nullable<
    NullableProps<
      SchemaPick<
        organizations,
        "logo_ref" | "type" | "service_provider_type" | "registered",
        false
      >
    >
  >
  size?: AvatarSize
}

export default function OrganizationAvatar({
  organization = null,
  size = "small",
  ...attributes
}: OrganizationAvatarProps) {
  const theme = useTheme()
  const { t } = useTranslation(de)
  const { icon } = useOrganizationIcon(organization)

  return (
    <Avatar
      iconPath={icon}
      iconColor={theme.palette.text.secondary}
      imageRef={organization?.logo_ref}
      imageFit="contain"
      size={size}
      badgeContent={
        organization?.registered && (
          <Tooltip title={t("registered")}>
            <Icon
              className="bg-white rounded-full"
              size={0.5}
              path={mdiCheckDecagram}
              color={theme.palette.primary.main}
            />
          </Tooltip>
        )
      }
      {...attributes}
    />
  )
}
