import { Chip, type ChipProps } from "@/components/ui/data-display/Chip"
import { Typography } from "@/components/ui/data-display/Typography"
import { useTranslation } from "@/i18n"
import type { organizations } from "dcp-types"
import {
  Fragment,
  forwardRef,
  useEffect,
  useMemo,
  type HTMLAttributes,
} from "react"
import OrganizationAvatar from "../OrganizationAvatar"
import de from "./_messages/de.json"
import { Tooltip } from "@/components/ui/data-display/Tooltip"
import { IconButton } from "@/components/ui/inputs/IconButton"
import Icon from "@mdi/react"
import { mdiPencil } from "@mdi/js"
import { Stack } from "@/components/ui/layout/Stack"
import type { IconButtonProps } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import type { Selectable } from "kysely"
import type { NullableProps } from "@/global"
interface OrganizationChipProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "children" | "color"> {
  organization?: NullableProps<
    Pick<
      Selectable<organizations>,
      "logo_ref" | "type" | "service_provider_type" | "name" | "registered"
    >
  > | null
  variant?: "chip" | "avatar"
  size?: "tiny" | "small" | "medium"
  onDelete?: ChipProps["onDelete"]
  onEdit?: IconButtonProps["onClick"]
  onClick?: HTMLAttributes<HTMLDivElement | HTMLButtonElement>["onClick"]
}

/**
 * TODO: add support for different sizes
 */

export const OrganizationChip = forwardRef<
  HTMLDivElement,
  OrganizationChipProps
>(
  (
    {
      organization,
      variant = "chip",
      size = "small",
      onDelete,
      onEdit,
      ...attributes
    },
    ref,
  ) => {
    const { t } = useTranslation(de)
    const chipSize = size !== "tiny" ? size : "small"
    const theme = useTheme()
    const chipTextColor = attributes.onClick ? "text.primary" : "text.disabled"

    const name = useMemo(
      () => organization?.name?.trim() || t("orgNameMissing.label"),
      [t, organization?.name],
    )

    if (!organization) {
      return null
    }

    useEffect(() => {
      if (variant === "avatar" && (onDelete || onEdit)) {
        console.error(
          "OrganizationChip: onDelete and onEdit are not supported for variant 'avatar'.",
        )
      }
    }, [onDelete, onEdit, variant])

    useEffect(() => {
      if (onDelete && onEdit) {
        console.error(
          "OrganizationChip: onDelete and onEdit are both defined. This is not supported, use either or.",
        )
      }
    }, [onDelete, onEdit])

    if (variant === "avatar") {
      const iconButtonSize = {
        tiny: "small",
        small: "medium",
        medium: "large",
      } as const

      return (
        <div ref={ref} className="max-w-full">
          <Tooltip title={organization?.name}>
            <div>
              <IconButton
                className="p-0"
                size={iconButtonSize[size]}
                onClick={(e) => attributes.onClick?.(e)}
                disabled={!attributes.onClick}
              >
                <OrganizationAvatar
                  organization={{
                    logo_ref: organization?.logo_ref ?? null,
                    type: organization?.type ?? null,
                    service_provider_type:
                      organization?.service_provider_type ?? null,
                    registered: organization?.registered ?? null,
                  }}
                  size={size}
                />
              </IconButton>
            </div>
          </Tooltip>
        </div>
      )
    }

    return (
      <div ref={ref} className="max-w-full">
        <Chip
          {...attributes}
          sx={{
            ["& .MuiChip-label"]: {
              paddingRight: onEdit ? 0 : undefined,
            },
            // @ts-expect-error
            ...(attributes?.sx ?? {}),
          }}
          className={`${size === "tiny" ? "h-5" : ""} ${attributes.className}`}
          avatar={
            <OrganizationAvatar
              className="ml-0.5"
              organization={{
                logo_ref: organization?.logo_ref ?? null,
                type: organization?.type ?? null,
                service_provider_type:
                  organization?.service_provider_type ?? null,
                registered: organization?.registered ?? null,
              }}
              size={size}
            />
          }
          label={
            <Stack direction={"row"} alignItems={"center"}>
              <Tooltip title={organization?.name}>
                <Typography
                  overflow={"hidden"}
                  textOverflow="ellipsis"
                  fontWeight={500}
                  variant={"body2"}
                  color={chipTextColor}
                >
                  {name}
                </Typography>
              </Tooltip>
              {onEdit && (
                <Tooltip title={t("edit.label")}>
                  <IconButton
                    disableRipple
                    sx={{
                      color: theme.palette.action.disabled,
                      ["&:hover"]: {
                        color: theme.palette.action.active,
                      },
                    }}
                    size="small"
                    onClick={(evt) => {
                      evt.stopPropagation()
                      return onEdit(evt)
                    }}
                  >
                    <Icon path={mdiPencil} size={0.75} />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
          }
          size={chipSize}
          onDelete={onDelete}
        />
      </div>
    )
  },
)

OrganizationChip.displayName = "OrganizationChip"

export default OrganizationChip
