export const getDocumentsBucketName = (organizationId: string) => {
  return `documents-${organizationId}`
}

export const getDocumentPath = (documentId: string, storageName: string) => {
  return `${documentId}/${storageName}`
}

export const getDocumentLayoutResultPath = (
  documentId: string,
): `${string}/analysis.json` => {
  return `${documentId}/analysis.json`
}

export const getEmailHtmlPath = (emailId: string): `${string}/body.html` => {
  return `${emailId}/body.html`
}

export const BucketName = {
  emails: "emails",
  signatures: "signatures",
  logos: "logos",
  avatars: "avatars",
} as const
