import {
  mdiAccountHardHat,
  mdiAccountTieHat,
  mdiBank,
  mdiCarKey,
  mdiFormatSection,
  mdiGavel,
  mdiOfficeBuilding,
  mdiPoliceStation,
  mdiShieldCar,
  mdiStore,
  mdiTowTruck,
  mdiWrench,
} from "@mdi/js"
import type { Nullable, NullableProps } from "@/global"
import type { organizations, service_provider_type } from "dcp-types"
import type { Selectable } from "kysely"
import { assertUnreachable } from "@/utils/types/helpers"

export const getIconByServiceProviderType = (
  type?: service_provider_type | null,
) => {
  switch (type) {
    case "surveyor":
      return mdiAccountHardHat
    case "car_dealership":
      return mdiStore
    case "car_rental":
      return mdiCarKey
    case "towing_service":
      return mdiTowTruck
    case null:
    case undefined:
      return mdiStore
  }
  return assertUnreachable(type, mdiStore)
}

const _getOrganizationIcon = (
  organization?: Nullable<
    Partial<
      NullableProps<
        Pick<Selectable<organizations>, "type" | "service_provider_type">
      >
    >
  >,
) => {
  const organizationType = organization?.type

  switch (organizationType) {
    case "service_provider":
      return getIconByServiceProviderType(organization?.service_provider_type)
    case "court":
      return mdiGavel
    case "insurance_company":
      return mdiShieldCar
    case "police_station":
      return mdiPoliceStation
    case "penalty_office":
      return mdiAccountTieHat
    case "public_prosecutor":
      return mdiFormatSection
    case "financial_institution":
      return mdiBank
    case "law_firm":
    case null:
    case undefined:
      return mdiOfficeBuilding
  }

  return assertUnreachable(organizationType, mdiOfficeBuilding)
}

export function useOrganizationIcon(
  organization: Nullable<
    Partial<
      NullableProps<
        Pick<Selectable<organizations>, "type" | "service_provider_type">
      >
    >
  >,
) {
  const icon = _getOrganizationIcon(organization)
  return { icon }
}
