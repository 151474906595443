import { isDataUrl } from "@/utils/data"
import { BucketName, getEmailHtmlPath } from "@/utils/storage/names"
import { arrayOfAll } from "@/utils/types/arrayOfAll"

export type TBucket = keyof typeof BucketName
export type TImageExtension = "png" | "jpeg" | "jpg"
export type TExtension = TImageExtension | "html"

/**
 * this list of supported image extensions is restricted to the ones that are
 * a) supported by pdfmake (png, jpeg, jpg, svg)
 * b) email clients (png, jpeg, jpg, gif)
 * so the intersection of both is png, jpeg, jpg
 */
export const supportedImageExtensions = arrayOfAll<TImageExtension>()([
  "png",
  "jpeg",
  "jpg",
])

export const isSupportedImageExtension = (
  extension: string,
): extension is TImageExtension => {
  return supportedImageExtensions.includes(extension as TImageExtension)
}

/**
 *
 * @param personId personId
 * @param extension extension without point
 * @returns
 */
export const generateAvatarPath = (
  personId: string,
  hash: string,
  extension: TImageExtension,
): `${TBucket}/${string}/${string}.${TImageExtension}` => {
  return `${BucketName.avatars}/${personId}/${hash}.${extension}`
}

/**
 *
 * @param emailId
 * @returns
 */
export const generateEmailHtmlPath = (
  emailId: string,
): `${TBucket}/${string}/body.html` => {
  return `${BucketName.emails}/${getEmailHtmlPath(emailId)}`
}

/**
 *
 * @param organizationId
 * @param extension
 * @returns
 */
export const generateLogoPath = (
  organizationId: string,
  hash: string,
  extension: TImageExtension,
): `${TBucket}/${string}/${string}.${TImageExtension}` => {
  return `${BucketName.logos}/${organizationId}/${hash}.${extension}`
}

/**
 *
 * @param personId
 * @returns
 */
export const generateSignaturePath = (
  personId: string,
): `${TBucket}/${string}.png` => {
  return `${BucketName.signatures}/${generateSignaturePathWithoutBucket(personId)}`
}

export const generateSignaturePathWithoutBucket = (
  personId: string,
): `${string}.png` => `${personId}/signature.png`

export const getResourceUrlByRef = (refPath?: string | null) => {
  const dataUrl = refPath

  if (isDataUrl(dataUrl)) {
    return dataUrl
  }

  if (refPath?.startsWith("http")) {
    return refPath
  }

  if (refPath) {
    if (refPath.includes("::")) {
      const [bucket, path] = refPath.split("::")
      return `/api/v1/resources/${bucket}/${path}`
    }

    return `/api/v1/resources/${refPath}`
  }

  return refPath ?? ""
}
